
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PODTrackerController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import SttPODImage from '../components/SttPODImage/index.vue'
import { PODDetail } from '@/domain/entities/PODTracker'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import EditIconAlt from '@/app/ui/assets/edit_icon_alt2.vue'

@Component({
  components: {
    Button,
    LoadingOverlay,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    SttPODImage,
    TextInput,
    EditIconAlt,
  },
})
export default class PODDetailPage extends Vue {
  isValidPod = false
  reason = ''
  updateNote = false

  created(): void {
    EventBus.$on(EventBusConstants.UPDATE_POD_HISTORY_SUCCESS, () => {
      this.updateNote = false
      controller.getPODDetail(this.$route.params.packageId)
    })

    controller.getPODDetail(this.$route.params.packageId)
  }

  get detail(): PODDetail {
    return controller.podDetail
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get phoneNumber(): string {
    return Utils.countryIndonesiaPhoneNumber(
      controller.podDetail.phoneNumber || '',
      true
    )
  }

  get date(): string {
    return dayjs(controller.podDetail.detailPOD?.podDate || '')
      .locale(dayjsID)
      .format('DD MMM YYYY')
  }

  private toggleValidPod() {
    this.isValidPod = !this.isValidPod
  }

  @Watch('detail')
  private updatedPODDetail(value: PODDetail) {
    this.isValidPod = value.detailPOD?.isValidPod || false
    this.reason = value.detailPOD?.history?.note || ''
  }

  @Watch('isValidPod')
  private updateValidPOD(value: boolean) {
    if (value === true && (this.detail.detailPOD?.isValidPod || false) === false) {
      this.reason = ''
      this.updatePODHistory()
    }
  }

  @Watch('reason')
  private updateReason(value: string) {
    // remove script tags
    this.reason = Utils.removeScriptTags(value)

    // remove html tags
    this.reason = Utils.removeHTMLTags(this.reason)

    // remove special characters
    this.reason = Utils.removeSpecialCharacters(this.reason)
  }

  private updatePODHistory() {
    controller.updatePODHistory({
      packageId: this.detail.detailPOD?.packageId as string,
      historyId: Number(this.detail.detailPOD?.history?.historyId),
      isValidPod: this.isValidPod,
      note: this.reason,
    })
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_POD_HISTORY_SUCCESS)
  }
}
