





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CloseIcon from '@/app/ui/assets/close_circle.vue'
import Chevron from '@/app/ui/assets/expand_icon.vue'

@Component({
  components: {
    CloseIcon,
    Chevron,
  },
})
export default class SttPODImage extends Vue {
  @Prop({ required: true }) private images!: string[]

  active: number | null = null

  @Watch('active')
  private onActive(val: string | null) {
    if (val !== null) {
      window.addEventListener('keydown', this.keyboardNavigation)
    } else {
      window.removeEventListener('keydown', this.keyboardNavigation)
    }
  }

  private keyboardNavigation(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.active = null
      return
    }
    if (e.key === 'ArrowRight') return this.nextImage()
    if (e.key === 'ArrowLeft') return this.prevImage()
  }

  private openImage(imageNumber: number) {
    this.active = imageNumber
  }

  get activeImage(): string | null {
    if (this.active === null) return null

    return this.images[this.active]
  }

  private nextImage() {
    if (this.active === this.images.length - 1) {
      this.active = 0
    } else {
      this.active = (this.active || 0) + 1
    }
  }

  private prevImage() {
    if (this.active === 0) {
      this.active = this.images.length - 1
    } else {
      this.active = (this.active || 0) - 1
    }
  }
}
